import React, { useState, useEffect, useContext } from "react";
import { navigate } from "gatsby";
import Header from "../components/Header";
import { AuthContext } from "../context/Auth";
import { addRating, getConsultationDetail } from "../services/apiServices";
import { AnalyticsContext } from "../context/Analytics";
import i from "../staticAssets/user.png";

function ConsultationEnd() {
    const [detail, setdetail] = useState({});
    const [rating, setrating] = useState(0);
    const { user, fetchUser } = useContext(AuthContext) || { user: {}, fetchUser: () => {} };
    const { gtmPageViewEvent } = useContext(AnalyticsContext) || { gtmPageViewEvent: () => {} };

    useEffect(() => {
        gtmPageViewEvent(window.location.pathname, window.location.host, user.meesho_userId);
    }, []);

    useEffect(() => {
        const fn = async () => {
            if (!window.history?.state?.id) {
                navigate("/consultAstro", {
                    replace: true,
                });
            }
            let res = await getConsultationDetail(window.history?.state?.id);
            if (res.statusCode === 200) {
                setdetail({ ...res.payLoad.consultationDetail });
            }
        };
        fn();
        fetchUser();
    }, []);

    const rate = async () => {
        try {
            let res = await addRating(rating, detail?._id);
        } catch (err) {
            console.error(err);
        }
        navigate("/consultAstro", {
            replace: true,
        });
    };

    return (
        <div className="flex flex-col h-screen">
            <Header />
            <header className="h-72">
                <div className="h-48 bg-red-100 flex items-center">
                    <button
                        className="ml-2"
                        onClick={() => {
                            navigate("/consultAstro", {
                                replace: true,
                            });
                        }}
                    ></button>
                </div>
                <div className="absolute top-40 w-full flex justify-center">
                    {detail?.astrologer?.imgUrl ? (
                        <img
                            loading="lazy"
                            src={detail?.astrologer?.imgUrl}
                            className="object-contain rounded-full h-40 w-40 bg-gray-100 shadow border border-white"
                            alt=""
                        />
                    ) : (
                        <img loading="lazy" src={i} className="object-contain rounded-full h-40 w-40 bg-gray-100 shadow border border-white" alt="" />
                    )}
                </div>
            </header>
            <main className="flex-1 overflow-auto-y flex flex-col container mx-auto">
                <div className="flex justify-center">
                    <h1 className="text-2xl">Rate your experience</h1>
                </div>
                <div className="flex justify-center p-1 m-2">
                    {[...Array(rating)].map((v, i) => {
                        return (
                            <button
                                key={i}
                                onClick={() => {
                                    setrating(i + 1);
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" viewBox="0 0 20 20" fill="#ff6a00">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                            </button>
                        );
                    })}
                    {[...Array(5 - rating)].map((v, i) => {
                        return (
                            <button
                                key={i}
                                onClick={() => {
                                    setrating(rating + i + 1);
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" viewBox="0 0 20 20" fill="#b3b3b3">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                            </button>
                        );
                    })}
                </div>
                <h2 className="ml-4 text-xl mt-2 text-gray-900">Call Summary</h2>
                <div className="flex mx-4 justify-between my-2">
                    <h2 className="text-gray-600">Chat Duration</h2>
                    <h2 className="text-gray-600">{detail?.durationInMins + " min"}</h2>
                </div>
                <div className="flex mx-4 justify-between my-1">
                    <h2 className="text-gray-600">Charges per Minute</h2>
                    <h2 className="text-gray-600">{(user?.currencyType === "usd" ? "$" : "₹") + " " + detail?.callRate}</h2>
                </div>
                <div className="flex mx-4 justify-between my-1 text-xl text-yellow-600">
                    <h2>Total Charges</h2>
                    <h2>{(user?.currencyType === "usd" ? "$" : "₹") + " " + detail?.totalAmountPaidWithTax}</h2>
                </div>
                <div className="flex w-full justify-center my-2">
                    <div className="w-10/12 h-0.5 bg-gray-200"></div>
                </div>
                <div className="flex mx-4 justify-between my-1">
                    <h2 className="text-gray-600">Wallet Balance</h2>
                    <h2 className="text-gray-600">{(user?.currencyType === "usd" ? "$" : "₹") + " " + user?.cashBalance}</h2>
                </div>
            </main>
            <footer className="m-4 flex justify-center">
                {rating > 0 ? (
                    <button
                        className="w-full md:w-2/3 py-2 bg-green-500 rounded-full text-white text-semibold text-lg shadow"
                        onClick={() => {
                            rate();
                        }}
                    >
                        Submit
                    </button>
                ) : (
                    <button disabled className="w-full md:w-2/3 py-2 bg-gray-200 rounded-full text-gray-400 text-semibold text-lg shadow">
                        Submit
                    </button>
                )}
            </footer>
        </div>
    );
}

export default ConsultationEnd;
